/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  $.noConflict();

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        $('body').on('change', '.inputfile', function(e) {
          var file = e.target.value.split( '\\' ).pop();
          $(this).parents('.input-box').find('.style-upload-btn em').html('');
          $(this).parents('.input-box').find('.holding-img').hide();
          $(this).parents('.input-box').find('.selected-img').html('Uploaded file: ' + file).css('padding', '10px 0');
        });

        $('#subForm').on('submit', function (e) {
          e.preventDefault();
          $.getJSON(
              this.action + "?callback=?",
              $(this).serialize(),
              function (data) {
                $('.subscribe-message').hide().removeClass('error');
                if (data.Status === 400) {
                  $('.subscribe-message').html("Oops, something went wrong, please try again.").addClass('error');
                } else {
                  $('.subscribe-message').html("Thanks for subscribing!");
                }

                $('#fieldEmail').val('');
                $('.subscribe-message').show();
              });
        });

        if ($('.validate-length').length) {
          $('.validate-length').keyup(function() {
            var countdown = $(this).parent('.input-box').find('strong');
            var maxLength = $(this).attr('maxlength');
            console.log(countdown);
            var length = maxLength - $(this).val().length;
            countdown.text(length);
          });
        }

        $('.tooltip').tooltipster({
          trigger: 'custom',
          triggerOpen: {
            click: true,
            tap: true
          },
          triggerClose: {
            click: true,
            scroll: true,
            tap: true
          },
          maxWidth: 320
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  function responsiveFixes() {

    var windowWidth = $(window).width();

    if ($('.catImgRight').length) {
      var catImgRight = $('.catImgRight').height();
      $('.catImg').css('height', catImgRight+'px');
    }

    if ($('.productList').length) {
      $('.productList').responsiveEqualHeightGrid();
    }

    if ($('.products-grid').length) {
      $('.products-grid li').responsiveEqualHeightGrid();
    }

    if ($('.preserveHeight').length) {
      $('.preserveHeight').responsiveEqualHeightGrid();
    }

    if ($('.product-image-gallery').length) {
      var mainImgHeight = $('.product-image-gallery').innerHeight();
      $('.smallPicsWrapper').css('height', mainImgHeight+'px');
    }

    if ($('.lSGallery').length && $('.blankImages').length) {
      var lsGal = $('.lSGallery li').outerWidth();
      $('.blankImages li').css('width', lsGal+'px');
    }
  }

  var lastScrollTop = 0;
  $(window).bind('scroll', function() {
    var st = $(this).scrollTop();

    if ($('.sell-with-us').length) {
      var navHeight = $('.header-line').innerHeight();
      if ($(window).scrollTop() > navHeight && $(window).scrollTop() > 0) {
        $('.page-header-container').addClass('fixed');
        $('.openingBgWr').css('margin-top', ( $('.page-header-container.fixed').innerHeight() )+'px');
      } else {
        $('.page-header-container').removeClass('fixed');
        $('.openingBgWr').css('margin-top', '0');
      }
    }

    if ($(window).width() < 768) {
      if (
          $(window).scrollTop() > 300
      ) {
        if (st > lastScrollTop) {
          $('.page-header-container.fixed').css('opacity', '0').css('top', '-200px');
        } else {
          $('.page-header-container.fixed').css('opacity', '1').css('top', '0');
        }
      } else {
        $('.page-header-container.fixed').css('opacity', '1').css('top', '0');
      }

      if ($('.menuWrapper').hasClass('active')) {
        $('.menuWrapper').removeClass('active');
        $('.menuOpen').show();
        $('.menuClose').hide();
      }
    }

    lastScrollTop = st;
  });

  $(window).resize(function() {
    responsiveFixes();
  });

  $(window).on("load", function() {
    if ($('.catImgRight').length) {
      var catImgRight = $('.catImgRight').height();
      $('.catImg').css('height', catImgRight+'px');
    }

    if ($('.product-image-gallery').length) {
      var mainImgHeight = $('.product-image-gallery').innerHeight();
      $('.smallPicsWrapper').css('height', mainImgHeight+'px');
    }

    if ($('.lSGallery').length && $('.blankImages').length) {
      var lsGal = $('.lSGallery li').outerWidth();
      $('.blankImages li').css('width', lsGal+'px');
    }

    responsiveFixes();
  });

  function onScroll(){
    var scrollPos = $(document).scrollTop()+$('.page-header-container').innerHeight()+5;
    if ($('.sell-with-us').length) {
      $('.menuWrapper > nav > ul > li').each(function () {
        var currLink = $(this);
        var refElement = $(currLink.find('.slideToMenu').attr("href"));

        if (typeof refElement.position() !== 'undefined') {
          if (refElement.position().top <= scrollPos && refElement.position().top + refElement.innerHeight() > scrollPos) {
            $('.menuWrapper > nav > ul > li').removeClass("active");
            currLink.addClass("active");
          } else{
            currLink.removeClass("active");
          }
        }
      });
    }
  }

  $(document).on("scroll", onScroll);

  $(document).ready(function() {

    responsiveFixes();

    $('.product-options-wrapper-trigger .personalise').click(function() {
      var productoptions = $('.product-options-wrapper');
      var personalisationoptions = $('.personalisation-options-enabled');

      if (productoptions.hasClass('active')) {
        productoptions.removeClass('active');
      } else {
        productoptions.addClass('active');
        personalisationoptions.hide();
      }

      //window.parent.dispatchEvent(new Event('resize'));

      $('.fancybox-inner', window.parent.document).css('height', $('.amquickview-ajax-view').height()+'px');
    });

    $('.back-hide-options').click(function(e) {
      e.preventDefault();
      var productoptions = $('.product-options-wrapper');
      var personalisationoptions = $('.personalisation-options-enabled');

      productoptions.removeClass('active');
      personalisationoptions.show();

      $('.fancybox-inner', window.parent.document).css('height', $('.amquickview-ajax-view').height()+'px');
    });

    $('.mobileToggleHeader').click(function(e) {
      e.preventDefault();
      var mobileToggle = $(this).parent().find('.mobileToggle');
      if (mobileToggle.hasClass('active')) {
        mobileToggle.removeClass('active');
        $(this).removeClass('active');
      } else {
        mobileToggle.addClass('active');
        $(this).addClass('active');
      }
    });

    $('#miniCartLink').click(function(e) {
      e.preventDefault();
    });

    $('.readMoreLink').click(function(e) {
      e.preventDefault();
      $('.readmore').toggleClass('active');
    });

    $('.popup-youtube').magnificPopup({
      disableOn: 700,
      type: 'iframe',
      mainClass: 'mfp-fade',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false
    });

    if ($('#responsiveTabs').length) {
      $('#responsiveTabs').responsiveTabs({
        startCollapsed: 'accordion'
      });
    }

    $('.refineTitle').click(function() {
      if($('.refineOptions').hasClass('active')) {
        $('.refineOptions').removeClass('active');
        $('.refineTitle').removeClass('active');
      } else {
        $('.refineOptions').addClass('active');
        $('.refineTitle').addClass('active');
      }
    });

    $('.favouriteCategories').on('click', function() {
      var ul = $(this).find('ul');
      var h3 = $(this).find('h3');
      var win = $(window).width();

      if (win < 768) {
        if (ul.hasClass('active')) {
          ul.removeClass('active');
          h3.removeClass('active');
        } else {
          ul.addClass('active');
          h3.addClass('active');
        }
      }
    });

    $('.layer-nav-item-add, #narrow-by-list2').on('click', function() {
      var ul = $(this).find('ul');
      var dt = $(this).find('dt');
      var win = $(window).width();

      if (win < 768) {
        if (ul.hasClass('active')) {
          ul.removeClass('active');
          dt.removeClass('active');
        } else {
          ul.addClass('active');
          dt.addClass('active');
        }
      }
    });

    $('.backToTop').click(function(e) {
      e.preventDefault();
      $('html, body').animate({ scrollTop: 0 }, "slow");
    });

    if ($('.productList').length) {
      $('.productList').responsiveEqualHeightGrid();
    }

    if ($('.item').length) {
      $('.item').responsiveEqualHeightGrid();
    }

    if ($('.preserveHeight').length) {
      $('.preserveHeight').responsiveEqualHeightGrid();
    }

    if ($('.products-grid').length) {
      $('.products-grid li').responsiveEqualHeightGrid();
    }

    if ($('.sidebar').length && !$('.favouriteCategories').length) {
      $('.sidebar h3').click(function() {
        if ($('.sidebar ul').hasClass('active')) {
          $('.sidebar ul').removeClass('active');
        } else {
          $('.sidebar ul').addClass('active');
        }
      });
    }

    if ($('#tabs').length) {
      $('#tabs').responsiveTabs({
        startCollapsed: 'accordion'
      });
    }

    $('.titleQ').click(function() {
      var answer = $(this).parent('.accordionWrapper').find('.answerQ');
      if (answer.hasClass('active')) {
        $(this).removeClass('active');
        answer.removeClass('active');
      } else {
        $(this).addClass('active');
        answer.addClass('active');
      }
    });

    $('#miniCartLink').click(function(e) {
      e.preventDefault();

      if ($('.miniCartList').hasClass('active')) {
        $('.miniCartList').removeClass('active');
      } else {
        $('.miniCartList').addClass('active');
      }
    });

    $(".catWrapper ul li").hover(function() {
      var id = $(this).attr('data-id');
      $(this).parents('.subMenu').find('.catWrapper ul li').removeClass('active');
      $(this).addClass('active');
      $(this).parents('.subMenu').find('div').removeClass('active');
      $(this).parents('.subMenu').find('#'+id).addClass('active');
    });

    $('.mobileMenu').click(function() {
      if ($('.menuWrapper').hasClass('active')) {
        $('.menuWrapper').removeClass('active');
        $('.menuOpen').show();
        $('.menuClose').hide();
      } else {
        $('.menuWrapper').addClass('active');
        $('.menuOpen').hide();
        $('.menuClose').show();
      }
    });

    $('ul').find('a.slideToMenu').click(function(e) {
      e.preventDefault();

      var $href = $(this).attr('href');
      var $anchor = $($href).offset();
      $('html, body').animate({
        scrollTop: $anchor.top
      }, 1000);
    });

    $('.productReview .showMore').on('click', function(e) {
      e.preventDefault();

      $('.reviewItem').removeClass('hidden');
      $(this).hide();
    });

    if ($('.trackorder-index-result').length) {
      $('.trackorder-index-result .order-info-box, .trackorder-index-result .order-info-box').wrapAll('<div class="clearfix fixPadding">');
    }

    if ($('.product-image-thumbs').length) {
      if ($(window).width() < 1024) {
        $('.product-image-thumbs').lightSlider({
          gallery: true,
          item: 1,
          mode: 'slide',
          loop: true,
          adaptiveHeight: false,
          thumbMargin: 10,
          thumbItem: 3,
          vertical: false
        });
      } else {
        $('.product-image-thumbs').lightSlider({
          gallery: true,
          item: 1,
          mode: 'fade',
          loop: true,
          adaptiveHeight: false,
          thumbMargin: 10,
          thumbItem: 6,
          vertical: false
        });
      }
    }

    if ($('.product-options-bottom').length) {
      $('.qty-wrapper').insertAfter($('.add-to-cart-wrapper'));
    }

    if ($('.lSGallery').length && $('.blankImages').length) {
      $('.blankImages').insertAfter($('.lSGallery'));
    }

    if ($('#responsiveTabs').length && $(window).width() < 768) {
      $('#responsiveTabs').insertAfter($('.product-social'));
    }

    if ($('ul.messages').length) {
      $("ul.messages").delay(5000).fadeOut();
    }

    if ($('.giftVouchersWrapper #options_4260_2').length) {
      $('.giftVouchersWrapper #options_4260_2').prop('checked', true);

      $('.giftVouchersWrapper #options_4260_3').bind('change', function(){
        var items = $('#product-options-wrapper dl dt:last-of-type, #product-options-wrapper dl dd:last-of-type');

        if ($(this).is(':checked')) {
          items.show();
        } else {
          items.hide();
        }
      });
    }

    // Menu logic
    $('.menu-first-level').on('click', function() {
      if ($(window).width() < 768) {
        $('.menuWrapper nav > ul > li').hide(); // hide all links
        $('.menuWrapper nav > ul').css('padding-top', '0'); // css fix
        $(this).parent('li').show().css('border', '0').find('.subMenu').show(); // show current li and submenu
        $(this).parent('li').find('a').eq(0).hide(); // hide current a link
        $(this).hide(); // hide current span
        $('.menuTitle ul li').eq(0).hide(); // hide title 'menu'
        $('.menuTitle ul li.first-level').css('display', 'block'); // show title 'back'
        $('.mobile-secondary-nav, .menu-secondary-navigation-container, .accLinks-mobile').hide(); // hide bottom navs
      }
    });

    $('.menu-second-level').on('click', function() {
      if ($(window).width() < 768) {
        var id = $(this).parent('li').data('id');
        $('.menuTitle ul li.first-level, .catWrapper, .type-material-wrapper').css('display', 'none'); // hide back menu title, material, catwrapper
        $('.menuTitle ul li.second-level').css('display', 'block'); // display second level menu title
        $(this).parents('.subMenu').find('.catItemsWrapper').show().find('#' + id).css('display', 'block'); // get data-id element and show relevant 'type'
      }
    });

    $('.first-level').on('click', function() {
      if ($(window).width() < 768) {
        $('.menuWrapper nav > ul > li, .menuWrapper nav > ul > li > a, .menu-first-level, .accLinks-mobile, .mobile-secondary-nav').show().removeAttr('style'); // show all links
        $('.subMenu').hide(); // hide submenu
        $('.menuTitle ul li').eq(0).show(); // show title 'menu'
        $('.menuWrapper nav > ul').removeAttr('style');
        $('.menu-secondary-navigation-container').show();
        $(this).hide(); // hide 'back' title
      }
    });

    $('.second-level').on('click', function() {
      if ($(window).width() < 768) {
        $('.catItemsWrapper').css('display', 'none');
        $('.subMenu, .catWrapper').css('display', 'block'); // show submenu and catwrapper
        $('.menuTitle ul li').eq(1).css('display', 'block'); // show title 'back'
        $(this).hide(); // hide 'back' title
      }
    });

    $('.third-level').on('click', function() {
      if ($(window).width() < 768) {
        $('.material').css('display', 'none');
        $('.type, .second-level').css('display', 'block'); // show type and second-level
        $(this).hide(); // hide 'back' title
      }
    });

    $('.bottom-type').on('click', function() {
      if ($(window).width() < 768) {
        $(this).parents('.type-material-wrapper').find('.type').css('display', 'none'); // hide type
        $(this).parents('.type-material-wrapper').find('.material').css('display', 'block'); // show material
        $('.second-level').css('display', 'none'); // hide title 'back'
        $('.third-level').css('display', 'block'); // show title 'back'
      }
    });

    if ($('.confirm-password input').length) {
      $('.password input, .confirm-password input').prop('type', 'password');
    }
  });

})(jQuery); // Fully reference jQuery after this point.
